import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import {
  faAngleDown,
  faAngleUp,
  faLocationArrow,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Card } from 'react-bootstrap'
import { getWeekdayLong, isOpen } from '../util'
import UserNav from './auth0/UserNav'
import './ShopInfo.scss'
import { AppContext } from '../app-context'
import { DateTime, WeekdayNumbers } from 'luxon'
import cover from '../images/qitea/cover.jpeg'

function ShopInfo() {
  const appContext = useContext(AppContext)!
  const { merchant, store } = appContext.shopInfo
  const [showOpeningHours, setShowOpeningHours] = useState(false)
  const now = DateTime.now()
  const { open, openingHour, nextOpeningHour } = isOpen(
    appContext.openingHours,
    now
  )
  const weekdays: WeekdayNumbers[] = [1, 2, 3, 4, 5, 6, 7]
  const renderNextOpening = () => {
    const nextOpeningDay =
      now.weekday === nextOpeningHour!.day
        ? 'today'
        : 'on ' + getWeekdayLong(nextOpeningHour!.day)
    return (
      <span className="text-danger fs-3 fw-bold">
        Closed.{' '}
        {nextOpeningHour &&
          `Open again ${nextOpeningDay} at ${nextOpeningHour.openTime!.toFormat(
            'HH:mm'
          )} `}
      </span>
    )
  }

  return (
    <div className="oo-shopinfo">
      {/* <div className="oo-user-nav-container">
        <UserNav></UserNav>
      </div> */}
      <div
        className="oo-shop-banner"
        style={{
          backgroundImage: `url('${cover}')`,
        }}
      ></div>
      <Card>
        <Card.Body>
          <h1 className="oo-shop-name">
            {merchant.name} {store.locationId}
          </h1>
          <Card.Text>
            <p
              className="oo-open-info"
              onClick={() => setShowOpeningHours(!showOpeningHours)}
            >
              {!open && renderNextOpening()}
              {open && (
                <span>
                  Open until {openingHour!.closeTime!.toFormat('HH:mm')} &nbsp;
                </span>
              )}
              <span className="oo-open-info-toggle">
                {showOpeningHours && <FontAwesomeIcon icon={faAngleUp} />}
                {!showOpeningHours && <FontAwesomeIcon icon={faAngleDown} />}
              </span>
              <br></br>
            </p>
            {showOpeningHours && (
              <div className="oo-open-info-details row justify-content-center">
                <div className="col-4">
                  {weekdays.map((wkn) => (
                    <p>{getWeekdayLong(wkn)}</p>
                  ))}
                  {/* {store.calendarParsed.map((c) => (
                    <p>{c.day}</p>
                  ))} */}
                </div>
                <div className="col-7">
                  {weekdays.map((wdn) => {
                    const openingHour = appContext.openingHours[wdn]
                    if (!openingHour.isOpen)
                      return <p className="text-danger">Closed</p>
                    return (
                      <p>
                        {openingHour.openTime!.toFormat('HH:mm')}-
                        {openingHour.closeTime!.toFormat('HH:mm')}
                      </p>
                    )
                  })}
                </div>
              </div>
            )}
            <ul className="oo-shop-contact-info">
              <li>
                <a href={'tel:' + store.tel}>
                  <FontAwesomeIcon icon={faPhone} /> 044 932 9339
                </a>
              </li>
              <li>
                <a
                  href={store.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} /> {merchant.name}
                </a>
              </li>
              <li>
                <a
                  href={store.googleMapsShorted}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLocationArrow} /> {store.location}
                </a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ShopInfo
