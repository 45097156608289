import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { round } from '../util'
import './StickyBasket.scss'

interface StickBasketProps {
  total: number
  show: boolean
  onclick: () => void
}

function StickyBasket({ total, show, onclick }: StickBasketProps) {
  return !show ? null : (
    <>
      <div className="oo-sticky-basket mx-0" onClick={onclick}>
        <div>
          View order &nbsp;
          <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
          &nbsp;€{round(total)}
        </div>
      </div>
    </>
  )
}

export default StickyBasket
