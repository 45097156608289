import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navbar } from 'react-bootstrap'
import { round } from '../util'
import './TopNav.scss'

interface TopBasketProps {
  total: number
  show: boolean
  onclick: () => void
}

function TopBasket({ total, show, onclick }: TopBasketProps) {
  return !show ? null : (
    <Navbar className="bg-dark oo-topnav" onClick={onclick}>
      <div className="oo-topnav-item oo-cart-container">
        <a href="#">
          View order &nbsp;
          <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
          &nbsp;€{round(total)}
        </a>
      </div>
    </Navbar>
  )
}

export default TopBasket
