import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  Product,
  SelectModifier,
  ModifierOption,
  MultiSelectModifier,
} from '../../models/domain'
import {
  calculateFinalPrice,
  clone,
  getVatFromIncludingVatPrice,
  round,
} from '../../util'
import './Modal.scss'
import { Quantity } from '../Quantity'

interface SimpleItemModalProps {
  addToBasket: (item: Product) => void
  item: Product
  showModal: boolean
  handleCloseThenCheckout: () => void
  handleClose: () => void
}

export function SimpleItemModal({
  item,
  showModal,
  handleClose,
  handleCloseThenCheckout,
  addToBasket,
}: SimpleItemModalProps) {
  const [multiSelectModifiers, setMultiSelectModifers] = useState<
    MultiSelectModifier[]
  >(item.multiSelectModifiers)
  const [modifiers, setModifiers] = useState<SelectModifier[]>(
    item.singleSelectModifiers
  )
  const [quantity, setQuantity] = useState(1)

  const onModifierOptionSelected = (
    modifier: SelectModifier,
    opt: ModifierOption
  ) => {
    const newModifiers = clone(modifiers) as SelectModifier[]
    const match = newModifiers.find((m) => m.name === modifier.name)
    match!.selected = opt
    setModifiers(newModifiers)
  }

  const multiSelectOptionClicked = (
    e: ChangeEvent<HTMLInputElement>,
    modifier: MultiSelectModifier,
    opt: ModifierOption
  ) => {
    const newModifiers = clone(multiSelectModifiers) as MultiSelectModifier[]
    const match = newModifiers.find((m) => m.name === modifier.name)!
    if (e.target.checked) {
      match.selected = Array.from(new Set([...match!.selected, opt]))
    } else {
      match.selected = match.selected.filter((i) => i.label !== opt.label)
    }

    if (match.selected.length > 2) {
      match.selected = match.selected.slice(match.selected.length - 2)
    }

    setMultiSelectModifers(newModifiers)
  }

  const handleAddThenCheckout = () => {
    const newItem: Product = {
      ...item,
      singleSelectModifiers: modifiers,
      multiSelectModifiers,
      qty: quantity,
    }
    addToBasket(newItem)
    handleCloseThenCheckout()
  }

  const handleAdd = () => {
    const newItem: Product = {
      ...item,
      singleSelectModifiers: modifiers,
      multiSelectModifiers,
      qty: quantity,
    }
    addToBasket(newItem)
    handleClose()
  }

  const getFinalPrice = () =>
    round(calculateFinalPrice(item, modifiers, multiSelectModifiers) * quantity)

  const getVat = () =>
    round(
      getVatFromIncludingVatPrice(
        calculateFinalPrice(item, modifiers, multiSelectModifiers) * quantity
      )
    )

  return (
    <Modal show={showModal} size="lg" className="oo-single-item-modal">
      <Modal.Body className="p-0 modal-lg">
        <div
          className="oo-modal-item-cover"
          style={{
            backgroundImage: `url('https://static.smartdine.fi/assets/qitea/${item.relImage}')`,
          }}
        >
          <a className="oo-close-icon-wrapper" onClick={handleClose}>
            <FontAwesomeIcon size="2x" icon={faTimes} />
          </a>
        </div>
        <h4 className="text-left p-3 border-bottom">Matcha bubble milk tea</h4>
        <div className="oo-modal-item-modifiers">
          {modifiers.map((modifier) => (
            <div className="oo-item-modifer p-3">
              <h6>
                {modifier.name}{' '}
                {modifier.note && <span>({modifier.note})</span>}
              </h6>
              {modifier.options.map((opt: ModifierOption) => (
                <div className="form-check">
                  <input
                    onChange={() => onModifierOptionSelected(modifier, opt)}
                    className="form-check-input"
                    type="radio"
                    name={opt.label}
                    id={opt.label}
                    value={opt.label}
                    checked={opt.label === modifier.selected.label}
                  />
                  <label className="form-check-label" htmlFor={opt.label}>
                    {opt.label} {opt.price > 0 && `(+${opt.price}€)`}
                  </label>
                </div>
              ))}
            </div>
          ))}{' '}
          {multiSelectModifiers.map((modifier) => (
            <div className="oo-item-modifer p-3">
              <h6>
                {modifier.name}{' '}
                {modifier.note && <span>({modifier.note})</span>}
              </h6>

              {modifier.options.map((opt: ModifierOption) => (
                <div className="form-check">
                  <input
                    id={opt.label}
                    type="checkbox"
                    className="form-check-input"
                    checked={modifier.selected
                      .map((s) => s.label)
                      .includes(opt.label)}
                    value={opt.label}
                    onChange={(e) => multiSelectOptionClicked(e, modifier, opt)}
                  />
                  <label className="form-check-label" htmlFor={opt.label}>
                    {opt.label} {opt.price > 0 && `(+${opt.price}€)`}
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="p-3">
          <h6>Quantity</h6>
          <Quantity quantity={quantity} setQuantity={setQuantity} />
        </div>
      </Modal.Body>
      <Modal.Footer className="oo-modal-footer">
        <div className="oo-price-breakdown align-right">
          <p className="text-start m-0 fs-5 ">Subtotal €{getFinalPrice()}</p>
          <p className="text-start text-secondary fs-6 mb-1">
            Incl VAT (14%) €{getVat()}
          </p>
        </div>
        <div className="d-flex flex-wrap">
          <Button
            variant="success"
            className="mb-2"
            onClick={handleAddThenCheckout}
            style={{ flexBasis: '100%' }}
          >
            Add to cart & Check out
          </Button>
          <Button variant="primary" onClick={handleAdd} className="mb-2 me-1">
            Add to cart
          </Button>
          <Button
            variant="outline-danger"
            onClick={handleClose}
            className="mb-2"
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
