import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLocationArrow, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BasketItem } from './components/modals/Modal'
import { ApiOrder, ApiOrderStatus, Basket } from './models/domain'
import './Order.scss'
import { getOrder } from './services/oo-be'
import Cover from './images/qitea/cover.jpeg'
import CoverVertical from './images/qitea/cover_vertical.jpeg'
import { getDeliveryEstimation, getLatestPickupTime, round } from './util'
import { DateTime } from 'luxon'
import { QITEA_MERC_NAME_ID } from './config'
import { AppContext } from './app-context'

const PickUpEstimation = ({ order }: { order: ApiOrder }) => {
  const appContext = useContext(AppContext)!
  const { merchant, store } = appContext.shopInfo
  const qty = (JSON.parse(order.jsonContent) as Basket).items
    .map((i) => i.qty)
    .reduce((lhs, rhs) => lhs + rhs)

  const orderStatusText = (status: ApiOrderStatus) => {
    switch (status) {
      case ApiOrderStatus.CREATED:
        return 'Waiting for confirmation from store'
      case ApiOrderStatus.CONFIRMED:
        return 'Order confirmed by store'
      case ApiOrderStatus.READY:
        return 'Order ready for pick-up'
      case ApiOrderStatus.DELIVERED:
        return 'Order delivered'
      default:
        return 'Order rejected'
    }
  }

  const renderDeliveryEstimation = () => (
    <>
      <p className="text-secondary m-0">Estimated pickup time</p>
      <p className="fw-bold fs-3 m-0">
        {getDeliveryEstimation(
          DateTime.fromISO(order.confirmedTs),
          appContext.openingHours,
          qty
        )}
      </p>
    </>
  )

  const renderWaitingConfirmation = () => (
    <>
      <p className="text-secondary m-0 fs-7">
        After confirmation, it should take around 20-30 mins to prepare a drink
      </p>
    </>
  )

  const renderReadyForPickUp = () => {
    const readyTs = DateTime.fromISO(order.readyTs)
    return (
      <>
        <p className="text-secondary m-0 fs-7">Pick up before:</p>
        <p className="m-0">
          <span className="fw-bold fs-3">
            {getLatestPickupTime(readyTs, appContext.openingHours)}
          </span>
        </p>
        <p className="text-secondary m-0 fs-7">
          The flavour of our tea is at its best when consumed within 3 hours
        </p>
      </>
    )
  }

  return (
    <div>
      <p className="m-0 fw-bold fs-4">{orderStatusText(order.status)}</p>
      {order.status === ApiOrderStatus.CREATED && renderWaitingConfirmation()}
      {order.status === ApiOrderStatus.CONFIRMED && renderDeliveryEstimation()}
      {order.status === ApiOrderStatus.READY && renderReadyForPickUp()}
      {/* {orderActions(order.status)} */}
      <hr></hr>
      {store && merchant && (
        <ul className="oo-shop-contact-info">
          <li>
            <a href={'tel:' + store.tel}>
              <FontAwesomeIcon icon={faPhone} /> 044 932 9339
            </a>
          </li>
          <li>
            <a href={store.instagram} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} /> {merchant.name}
            </a>
          </li>
          <li>
            <a
              className="text-reset"
              href={store.googleMapsShorted}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLocationArrow} /> {store.location}
            </a>
          </li>
        </ul>
      )}
    </div>
  )
}

const OrderReview = ({ order }: { order: ApiOrder }) => {
  const createdTs = DateTime.fromISO(order.createdTs)
  const basket = JSON.parse(order.jsonContent) as Basket

  return (
    <div>
      <p className="m-0 px-3 pt-3 fs-2">
        Order #<span className="fw-bold">{order.orderNo}</span>
      </p>
      {order.name && (
        <p className="m-0 px-3 fs-6 text-secondary">by {order.name}</p>
      )}
      <p className="m-0 px-3 fs-6 text-secondary">
        {createdTs.toLocaleString(DateTime.DATE_FULL)}
      </p>
      <div className="basket-items-wrapper border-bottom">
        {basket.items.map((item) => (
          <BasketItem
            item={item}
            setItemQuantity={() => {}}
            immutable={true}
            showRemoveIcon={false}
          ></BasketItem>
        ))}
      </div>
      <p className="text-end fs-4 m-0 p-2 border-top pt-2">
        Total <span className="fw-bold">€{round(basket.total)}</span>
      </p>
    </div>
  )
}

const Order = () => {
  const navigate = useNavigate()
  const { orderId } = useParams<{ orderId: string }>()
  const [order, setOrder] = useState<ApiOrder | undefined>()
  let orderUpdateInterval: NodeJS.Timeout

  const newOrder = () => {
    delete localStorage[`${QITEA_MERC_NAME_ID}_basket`]
    navigate('/')
  }

  function setOrderStatusUpdateInterval(order: ApiOrder) {
    orderUpdateInterval && clearInterval(orderUpdateInterval)
    if (order.status === ApiOrderStatus.CREATED) {
      orderUpdateInterval = setInterval(initOrder, 5 * 1000)
    }
    if (order.status === ApiOrderStatus.CONFIRMED) {
      orderUpdateInterval = setInterval(initOrder, 30 * 1000)
    }
  }

  async function initOrder() {
    const order = await getOrder(orderId!)
    delete localStorage[`${QITEA_MERC_NAME_ID}_basket`]
    setOrder(order)
    setOrderStatusUpdateInterval(order)
  }

  useEffect(() => {
    initOrder()
  }, [])

  return (
    <div className="">
      <div className="container-md">
        <div
          className="oo-order-content row justify-content-between align-items-start
         px-2 pt-2 pb-1 g-0 mt-md-4"
        >
          <div className="oo-est-container bg-light mb-5 col-12 col-md-6 rounded p-3">
            {order && <PickUpEstimation order={order}></PickUpEstimation>}
          </div>
          <div className="oo-review-container col-12 col-md-5">
            <div className="bg-white rounded">
              {order && <OrderReview order={order}></OrderReview>}
            </div>
            <p
              className="p-0 mt-2 mb-1 col-12 bg-dark p-2 rounded text-white text-center"
              role="button"
              onClick={newOrder}
            >
              Place new order
            </p>
          </div>
        </div>
      </div>
      {/* Background */}
      <div
        className="oo-order-map-bg d-none d-lg-block"
        style={{ backgroundImage: `url(${Cover})` }}
      ></div>
      <div
        className="oo-order-map-bg d-block d-lg-none"
        style={{ backgroundImage: `url(${CoverVertical})` }}
      ></div>
    </div>
  )
}

export default Order
