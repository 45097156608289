import React, { useEffect, useRef, useState } from 'react'

import { Button, Modal, Form, Spinner } from 'react-bootstrap'
import { Basket, CustomerInfo } from '../../models/domain'
import './Modal.scss'
import { BasketItem } from './Modal'
import { getVatFromIncludingVatPrice, round } from '../../util'
import parsePhoneNumber from 'libphonenumber-js'
import { default as paymentBanner } from '../../images/visa_mastercard_maestro_mobilepay.svg'
import {
  customerInfoLocalStorageKey,
  REACT_APP_MOCK_CUSTOMER_INFO,
} from '../../config'

interface CheckoutModalProps {
  showModal: boolean
  handleClose: () => void
  handleNext: (customerInfo: CustomerInfo) => Promise<void>
  basket: Basket
  updateBasket: (basket: Basket) => void
}

export function CheckoutModal({
  showModal,
  handleClose,
  handleNext,
  basket,
}: CheckoutModalProps) {
  const noop = () => {}
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(
    REACT_APP_MOCK_CUSTOMER_INFO
      ? {
          name: 'Phuc Tran',
          email: 'phuc.trandt@outlook.com',
          phone: '+358405017499',
        }
      : { name: '', email: '', phone: '' }
  )
  const [validated, setValidated] = useState(false)
  const [acceptedToc, setAcceptedToc] = useState(
    REACT_APP_MOCK_CUSTOMER_INFO ? true : false
  )
  const [rawPhone, setRawPhone] = useState(customerInfo.phone ?? '+358')
  const [processingSubmit, setProcessingSubmit] = useState(false)

  useEffect(() => {
    try {
      const localCustomer = localStorage.getItem(customerInfoLocalStorageKey)
      if (localCustomer) {
        const localCustomerInfo = JSON.parse(localCustomer) as CustomerInfo
        setCustomerInfo(localCustomerInfo)
        setRawPhone(localCustomerInfo.phone)
      }
    } catch (e) {
      console.error('Failed to load local customer', e)
    }
  }, [])

  const updateInfo = (partial: Partial<CustomerInfo>) => {
    const newCustomerInfo = {
      ...customerInfo,
      ...partial,
    }
    setCustomerInfo(newCustomerInfo)
  }

  const cancel = () => {
    handleClose()
  }

  const checkPhone = () => {
    const errMsg = `Format should be +358 4321...`
    const formatErrMsg = `The format
    should be +358 4321...`

    if (!rawPhone) {
      phoneInputRef!.current!.setCustomValidity(errMsg)
      return
    }

    const phoneNumber = parsePhoneNumber(rawPhone)
    if (!phoneNumber || !phoneNumber.isValid()) {
      phoneInputRef!.current!.setCustomValidity(formatErrMsg)
      return
    }

    // Empty valid msg ~ set input field to valid
    customerInfo.phone = phoneNumber!.number
    phoneInputRef!.current!.setCustomValidity('')
  }

  const onSubmitClick = () => {
    checkPhone()
    setValidated(true)
  }

  const onPhoneInputBlur = () => {
    setValidated(true)
    checkPhone()
  }

  const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const isValid = form.checkValidity()
    if (isValid) {
      setProcessingSubmit(true)
      handleNext(customerInfo).finally(() => setProcessingSubmit(false))
    }
  }

  const renderForm = () => (
    <Form id="user-details-form" validated={validated} onSubmit={onSubmit}>
      <h5 className="mb-3">Contact details</h5>
      <div className="form-floating mb-3">
        <Form.Control
          required
          placeholder="e.g. John Doe"
          value={customerInfo.name}
          onChange={(e) => updateInfo({ name: e.target.value })}
          id="inputName"
        ></Form.Control>
        <Form.Label htmlFor="inputName">Your name</Form.Label>
        <Form.Control.Feedback type="invalid">
          Please provide a name{' '}
        </Form.Control.Feedback>
      </div>
      <div className="form-floating mb-3">
        <Form.Control
          required
          type="email"
          value={customerInfo.email}
          onChange={(e) => updateInfo({ email: e.target.value })}
          placeholder="Your email here"
          id="inputEmail"
        ></Form.Control>
        <Form.Label htmlFor="inputEmail">Email address</Form.Label>
        <Form.Control.Feedback type="invalid">
          We need the email just in case we can't reach you via text :)
        </Form.Control.Feedback>
      </div>
      <div className="form-floating mb-3">
        <Form.Control
          required
          type="phone"
          value={rawPhone}
          onChange={(e) => setRawPhone(e.target.value)}
          onBlur={onPhoneInputBlur}
          placeholder="Your number here e.g. +358 4321..."
          id="inputPhone"
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          ref={phoneInputRef}
        ></Form.Control>
        <Form.Label htmlFor="inputPhone">
          Phone (with country code e.g. +358)
        </Form.Label>
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </div>
      <div className="form-floating mb-3">
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            className="me-2"
            id="accept-toc-checkbox"
            checked={acceptedToc}
            onChange={() => setAcceptedToc(!acceptedToc)}
            required
          ></Form.Check.Input>
          <Form.Check.Label htmlFor="accept-toc-checkbox">
            I agree to the{' '}
            <a
              className="link-secondary"
              target="_blank"
              href="https://static.smartdine.fi/toc.html"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className="link-secondary"
              target="_blank"
              href="https://static.smartdine.fi/"
            >
              Privacy Notice
            </a>
          </Form.Check.Label>
          <img className="w-100 mt-4" src={paymentBanner} alt="" />
        </Form.Check>
      </div>
    </Form>
  )

  return (
    <Modal show={showModal} centered className="oo-basket-modal">
      <Modal.Body className="p-0">
        <h4 className="text-left p-3 border-bottom">Checkout</h4>
        <div className="basket-items-wrapper border-bottom">
          {basket.items.map((item) => (
            <BasketItem
              item={item}
              setItemQuantity={noop}
              immutable={true}
              showRemoveIcon={false}
            ></BasketItem>
          ))}
          <p className="text-end fs-5 m-0 pe-3">
            Subtotal <span className="fw-bold">€{round(basket.total)}</span>
          </p>
          <p className="text-end text-secondary fs-6 pe-3">
            Incl. VAT (14%) €{round(getVatFromIncludingVatPrice(basket.total))}
          </p>
        </div>
        <div className="text-left p-3">{renderForm()}</div>
      </Modal.Body>
      <Modal.Footer
        className="oo-modal-footer d-flex justify-content-start"
        style={{ paddingRight: '60px' }}
      >
        <Button
          form="user-details-form"
          variant="primary"
          type="submit"
          disabled={processingSubmit}
          onClick={onSubmitClick}
        >
          {processingSubmit && (
            <Spinner
              animation="border"
              role="status"
              className="spinner-border-sm"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {!processingSubmit && `Proceed to pay €${round(basket.total)}`}
        </Button>
        <Button variant="outline-danger" onClick={cancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
