import './custom.scss'
import './App.scss'
import TopBasket from './components/TopNav'
import ShopInfoComponent from './components/ShopInfo'
import MenuByCategory from './components/MenuByCategory'
import { Basket, Product, CustomerInfo, ApiInfo } from './models/domain'
import { useContext, useEffect, useState } from 'react'
import { calcTotal, clone } from './util'
import StickyBasket from './components/StickyBasket'
import { BasketModal } from './components/modals/BasketModal'
import { CheckoutModal } from './components/modals/CheckoutModal'
import { getApiInfo, submitOrder } from './services/oo-be'
import { AppContext } from './app-context'
import {
  customerInfoLocalStorageKey,
  shopBasketLocalStorageKey,
} from './config'
import { PaymentModal } from './components/modals/PaymentModal'

function App() {
  const { shopInfo } = useContext(AppContext)!
  const [showBasketModal, setShowBasketModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [totalInMinorUnits, setTotalInMinorUnits] = useState(0)
  const [basket, setBasket] = useState<Basket>({
    items: [],
    total: 0,
    showBasket: false,
  })

  const onCheckoutNext = async (customerInfo: CustomerInfo) => {
    localStorage.setItem(
      customerInfoLocalStorageKey,
      JSON.stringify(customerInfo)
    )
    const { id: orderId, totalInMinorUnits } = await submitOrder({
      ...basket,
      customerInfo,
    })
    setOrderId(orderId)
    setTotalInMinorUnits(totalInMinorUnits)
    setShowCheckoutModal(false)
    setShowPaymentModal(true)
  }

  const setBasketAndSaveToLocalStorage = (basket: Basket) => {
    setBasket(basket)
    localStorage.setItem(shopBasketLocalStorageKey, JSON.stringify(basket))
  }

  const restoreBasketFromCache = () => {
    const localBasket = localStorage.getItem(shopBasketLocalStorageKey)
    if (localBasket) {
      setBasket(JSON.parse(localBasket))
    }
  }

  useEffect(() => {
    // Init shop & basket
    async function initShopInfo() {
      const apiInfoPromise = getApiInfo()

      // Only restore cache if api model has not change
      // Else empty cache
      const apiInfo = await apiInfoPromise
      const localApiInfoStr = localStorage.getItem('info')
      if (localApiInfoStr) {
        const localInfo: ApiInfo = JSON.parse(localApiInfoStr)
        if (localInfo.version === apiInfo.version) {
          restoreBasketFromCache()
        } else {
          localStorage.clear()
        }
      }

      // Either way must save info for future check
      localStorage.setItem('info', JSON.stringify(apiInfo))
    }
    initShopInfo()
  }, [])

  const addToBasket = (item: Product) => {
    const newItems = clone(basket.items)
    newItems.push(item)
    setBasketAndSaveToLocalStorage({
      ...basket,
      items: newItems,
      total: calcTotal(newItems),
      showBasket: newItems.length > 0,
    })
  }

  const updateBasket = (basket: Basket) => {
    setBasketAndSaveToLocalStorage({
      ...basket,
      total: calcTotal(basket.items),
      showBasket: basket.items.length > 0,
    })
  }

  const basketModalHandleNext = () => {
    setShowBasketModal(false)
    setShowCheckoutModal(true)
  }

  return (
    <div className="App g-0 container-lg">
      <TopBasket
        total={basket.total}
        show={basket.showBasket}
        onclick={() => setShowBasketModal(true)}
      ></TopBasket>
      <ShopInfoComponent />
      <MenuByCategory
        addToBasket={addToBasket}
        menuByCategory={shopInfo.menuByCategories}
        checkout={() => setShowCheckoutModal(true)}
      ></MenuByCategory>
      <BasketModal
        basket={basket}
        showModal={showBasketModal}
        handleClose={() => setShowBasketModal(false)}
        handleNext={basketModalHandleNext}
        updateBasket={updateBasket}
      ></BasketModal>
      <CheckoutModal
        basket={basket}
        showModal={showCheckoutModal}
        handleClose={() => setShowCheckoutModal(false)}
        handleNext={(ci: CustomerInfo) => onCheckoutNext(ci)}
        updateBasket={updateBasket}
      ></CheckoutModal>
      <PaymentModal
        showModal={showPaymentModal}
        totalInMinorUnits={totalInMinorUnits}
        orderId={orderId}
        handleClose={() => setShowPaymentModal(false)}
        handleNext={() => setShowPaymentModal(false)}
      ></PaymentModal>
      <StickyBasket
        total={basket.total}
        show={basket.showBasket}
        onclick={() => setShowBasketModal(true)}
      ></StickyBasket>
      <div className="footer text-center p-2 mt-4 text-secondary">
        Copyright © 2022 Smartdine. All Rights Reserved.{' '}
        <a
          className="link-secondary"
          target="_blank"
          href="https://static.smartdine.fi/toc.html"
        >
          Terms of Service
        </a>
        &nbsp;
        <a
          className="link-secondary"
          target="_blank"
          href="https://static.smartdine.fi/"
        >
          Privacy notice
        </a>
      </div>
    </div>
  )
}

export default App
