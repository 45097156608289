import { Button, Modal, Spinner } from 'react-bootstrap'
import './Modal.scss'
import { default as cardBanner } from '../../images/visa_mastercard_maestro.png'
import { default as mobilePayBtn } from '../../images/mobilepay_btn.png'
import ApplePay from '../ApplePay'
import { getCheckoutUrl } from '../../services/oo-be'
import { BamboraPaymentMethod } from '../../models/domain'
import { useEffect, useState } from 'react'

interface PaymentModalProps {
  showModal: boolean
  totalInMinorUnits: number
  orderId: string
  handleClose: () => void
  handleNext: () => void
}

export function PaymentModal({
  showModal,
  orderId,
  totalInMinorUnits,
  handleClose,
}: PaymentModalProps) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Allow sometime to load Apple Pay/ Google Pay scripts
    setTimeout(() => setLoading(false), 1000)
  }, [])

  const cancel = () => {
    handleClose()
  }

  const onMobilePayClicked = async () => {
    const checkoutSession = await getCheckoutUrl(
      orderId,
      BamboraPaymentMethod.MOBILEPAY
    )
    window.location.href = checkoutSession.url
  }
  const onCardsClicked = async () => {
    const checkoutSession = await getCheckoutUrl(
      orderId,
      BamboraPaymentMethod.CARD
    )
    window.location.href = checkoutSession.url
  }

  return (
    <Modal show={showModal} centered className="oo-payment-modal">
      {/* <Spinner animation="border" variant="secondary" />
          <Spinner animation="border" variant="secondary" />
          <Spinner animation="border" variant="secondary" /> */}
      <Modal.Body className="p-3">
        <h4 className="text-left pb-2">Please pick a payment method</h4>
        {loading && (
          <>
            <Spinner animation="border" variant="secondary" />
          </>
        )}
        {!loading && (
          <>
            <Button
              variant="outline p-0 w-100 text-start"
              onClick={onMobilePayClicked}
            >
              <img src={mobilePayBtn} alt="" />
            </Button>
            <Button
              variant="outline-primary p-0 mt-3 text-start card-payment-btn"
              onClick={onCardsClicked}
            >
              <img src={cardBanner} className="w-100" alt="" />
            </Button>
            <div className="mt-3">
              <ApplePay
                totalInMinorUnits={totalInMinorUnits}
                orderId={orderId}
              ></ApplePay>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="oo-modal-footer">
        <Button variant="outline-danger" onClick={cancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
