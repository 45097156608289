import { Product, MenuByCategories } from '../models/domain'
import './MenuByCategory.scss'
import SingleMenuItem from './SingleMenuItem'

interface MenuByCategoryProps {
  addToBasket: (item: Product) => void
  menuByCategory: MenuByCategories
  checkout: () => void
}

export default function MenuByCategoy({
  addToBasket,
  menuByCategory,
  checkout,
}: MenuByCategoryProps) {
  return (
    <div className="oo-menu-by-category">
      {menuByCategory.map((menu) => {
        return (
          <>
            <h2 className="oo-category-name">{menu.categoryName}</h2>
            <div className="row px-md-3 pb-3">
              {menu.items
                .filter((i) => i.available)
                .map((item) => (
                  <>
                    <SingleMenuItem
                      checkout={checkout}
                      item={item}
                      addToBasket={addToBasket}
                    ></SingleMenuItem>
                  </>
                ))}
            </div>
          </>
        )
      })}
    </div>
  )
}
