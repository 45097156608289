import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Basket } from '../../models/domain'
import {
  calcTotal,
  clone,
  getVatFromIncludingVatPrice,
  round,
} from '../../util'
import './Modal.scss'
import { BasketItem } from './Modal'
import { AppContext } from '../../app-context'

interface BasketModalProps {
  showModal: boolean
  handleClose: () => void
  handleNext: () => void
  basket: Basket
  updateBasket: (basket: Basket) => void
}

export function BasketModal({
  showModal,
  handleClose,
  basket,
  updateBasket,
  handleNext,
}: BasketModalProps) {
  const [localBasket, setLocalBasket] = useState<Basket>(clone(basket))
  const { isOpenAtm } = useContext(AppContext)!

  const syncWithParentBasket = () => setLocalBasket(clone(basket))

  const cancel = () => {
    syncWithParentBasket()
    handleClose()
  }

  const proceed = () => {
    updateBasket(localBasket)
    handleNext()
  }

  const addMoreItem = () => {
    updateBasket(localBasket)
    handleClose()
  }

  useEffect(syncWithParentBasket, [basket])

  return (
    <Modal show={showModal} centered className="oo-basket-modal">
      <Modal.Body className="p-0">
        <h4 className="text-left p-3 border-bottom">My order</h4>
        {localBasket.items.map((item) => {
          const setItemQuantity = (
            setStateAction: React.SetStateAction<number>
          ) => {
            item.qty = setStateAction as number // forcing because we know it's not a callback
            localBasket.items = localBasket.items.filter((item) => item.qty > 0)
            localBasket.total = calcTotal(localBasket.items)
            setLocalBasket(clone(localBasket))
          }
          return (
            <BasketItem
              item={item}
              setItemQuantity={setItemQuantity}
              showRemoveIcon={true}
            ></BasketItem>
          )
        })}
        {localBasket.items.length > 0 && (
          <>
            <p className="text-end fs-5 m-0 pe-3 border-top pt-2">
              Subtotal{' '}
              <span className="fw-bold">€{round(localBasket.total)}</span>
            </p>
            <p className="text-end text-secondary fs-6 pe-3">
              Incl. VAT (14%) €
              {round(getVatFromIncludingVatPrice(localBasket.total))}
            </p>
          </>
        )}
      </Modal.Body>
      {isOpenAtm && (
        <Modal.Footer className="oo-modal-footer justify-content-start">
          {localBasket.items.length > 0 && (
            <Button variant="primary" onClick={proceed}>
              Checkout
            </Button>
          )}
          <Button variant="primary" onClick={addMoreItem}>
            Save
          </Button>
          <Button variant="outline-danger" onClick={cancel}>
            Cancel
          </Button>
        </Modal.Footer>
      )}
      {!isOpenAtm && (
        <Modal.Footer className="oo-modal-footer">
          <p className="text-danger">
            Cannot pre order at the moment, shop is closed!
          </p>
          <Button variant="primary" onClick={cancel}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
