import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Product } from '../models/domain'
import { round } from '../util'
import { SimpleItemModal } from './modals/SimpleItemModal'
import './SingleMenuItem.scss'

interface MenuItemProps {
  item: Product
  addToBasket: (item: Product) => void
  checkout: () => void
}

export default function SingleMenuItem({
  item,
  addToBasket,
  checkout,
}: MenuItemProps) {
  const { title, description, basePrice: basePrice, relImage, popular } = item
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleCloseThenCheckout = () => {
    setShowModal(false)
    checkout()
  }
  return (
    <>
      <SimpleItemModal
        addToBasket={addToBasket}
        item={item}
        showModal={showModal}
        handleClose={handleClose}
        handleCloseThenCheckout={handleCloseThenCheckout}
      ></SimpleItemModal>
      <div className="col-sm-6 col-lg-4 mb-2">
        <Card
          className={
            (item.available ? '' : 'not-avail') + ' oo-menu-item-card h-100'
          }
          onClick={() => item.available && setShowModal(true)}
        >
          <div className="oo-menu-item p-3">
            <div className="oo-menu-item-left-content">
              <p className="oo-menu-item-title">{title}</p>
              <p className="oo-menu-item-desc">{description}</p>
              <p className="oo-menu-item-pricing">
                €{round(basePrice)}
                {popular && (
                  <span className="oo-menu-item-popular">
                    <FontAwesomeIcon icon={faStar} /> Popular
                  </span>
                )}
              </p>
              {!item.available && (
                <p className="oo-menu-item-avail">Sold out</p>
              )}
            </div>
            {!!relImage && (
              <div className="oo-menu-item-right-content">
                {/* <img src={matchaJpg} alt="yolo" /> */}
                <img
                  src={`https://static.smartdine.fi/assets/qitea/${relImage}`}
                  alt={relImage}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  )
}
