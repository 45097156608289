import React from 'react'
import { Product } from '../../models/domain'
import { calculateFinalPrice, round } from '../../util'
import './Modal.scss'
import { Quantity } from '../Quantity'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface BasketItemProps {
  item: Product
  setItemQuantity: React.Dispatch<React.SetStateAction<number>>
  showRemoveIcon: boolean
  immutable?: boolean
}

export function BasketItem({
  item,
  setItemQuantity,
  immutable,
  showRemoveIcon,
}: BasketItemProps) {
  const removeItem = () => setItemQuantity(0)
  return item.qty ? (
    <div className="d-flex p-3 justify-content-between">
      <div className="d-flex">
        <Quantity
          quantity={item.qty!}
          setQuantity={setItemQuantity}
          immutable={immutable}
        />
        <div className="basket-item-desc">
          <p className="item-name p-0 m-0">{item.title}</p>
          <ul className="item-breakdown list-unstyled m-0 p-0">
            <li>Drink €{round(item.basePrice)}</li>
            {item.singleSelectModifiers.map((m) => (
              <li>
                {m.selected.label}{' '}
                {m.selected.price > 0 && `€${round(m.selected.price)}`}
              </li>
            ))}
            {item.multiSelectModifiers.map((m) => {
              const total = m.selected
                .map((o) => o.price)
                .reduce((a, b) => a + b, 0)
              return (
                <li>
                  {m.selected.map((s) => s.label).join(', ')}{' '}
                  {total > 0 && `€${round(total)}`}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="text-center center">
        €{round(calculateFinalPrice(item) * item.qty!)}
        <br></br>
        {showRemoveIcon && (
          <span
            className="p-1 fs-3 basket-item-remove-icon"
            role="button"
            onClick={removeItem}
          >
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'orange' }} />
          </span>
        )}
      </div>
    </div>
  ) : null
}
