import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Order from './Order'
import ScrollToTop from './components/ScrollToTop'
import { getShopData } from './services/oo-be'
import { QITEA_MERC_NAME_ID, STRIPE_PUBLISHABLE_KEY } from './config'
import { AppContext, getAppContext } from './app-context'
import { ShopInfo } from './models/domain'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// todo: fetch this from the server
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const shopPath = 'qitea'

const AppWithContext = () => {
  const [shopInfo, setShopInfo] = useState<ShopInfo | undefined>(undefined)
  useEffect(() => {
    // Init shop & basket
    async function initShopInfo() {
      const shopInfo = await getShopData(QITEA_MERC_NAME_ID)
      setShopInfo(shopInfo)
    }
    initShopInfo()
  }, [])

  if (!shopInfo) return null
  const appContext = getAppContext({ shopInfo: shopInfo! })

  return (
    <AppContext.Provider value={appContext}>
      <Routes>
        <Route path={`/${shopPath}/`} element={<App></App>}></Route>
        <Route
          path={`/${shopPath}/order/:orderId`}
          element={<Order></Order>}
        ></Route>
        <Route path="*" element={<Navigate to={`/${shopPath}/`} />}></Route>
      </Routes>
    </AppContext.Provider>
  )
}

Sentry.init({
  dsn: 'https://23d182b1a82943a09226a937258b2279@o1335046.ingest.sentry.io/6602443',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain="smartdining.eu.auth0.com"
      clientId="vvArcZWOR0sKHtUVZQXUZaOflCzO1jn3"
      redirectUri={window.location.origin}
    >
      <React.StrictMode>
        <ScrollToTop>
          <Elements stripe={stripePromise}>
            <AppWithContext />
          </Elements>
        </ScrollToTop>
      </React.StrictMode>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
