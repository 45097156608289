import fetch, { Headers } from 'node-fetch'
import { OO_BE_URL_V1 } from '../config'
import { CheckoutSession } from '../models/checkout_session'
import {
  ApiOrder,
  ShopInfo,
  Basket,
  CustomerInfo,
  MenuByCategories,
  MultiSelectModifier,
  SelectModifier,
  ApiInfo,
  ApiShopInfo,
  BamboraPaymentMethod,
} from '../models/domain'
import { round } from '../util'

type SubmitOrderPayload = Basket & { customerInfo: CustomerInfo }

export async function checkPmtSessionSuccess(
  paymentIntentId: string | undefined,
  orderId: string
): Promise<boolean> {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const postRes = await fetch(`${OO_BE_URL_V1}payment/success`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      paymentIntentId,
      orderId,
    }),
  })

  return postRes.ok
}

export async function createPmtSession() {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const postRes = await fetch(`${OO_BE_URL_V1}payment/applepay`, {
    method: 'POST',
    headers,
  })

  const opaqueSession = await postRes.json()
  return opaqueSession
}

export async function getCheckoutUrl(
  orderId: string,
  paymentMethod: BamboraPaymentMethod
): Promise<CheckoutSession> {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const postRes = await fetch(`${OO_BE_URL_V1}order/pmt/session`, {
    method: 'POST',
    body: JSON.stringify({ paymentMethod, orderId }),
    headers,
  })

  const checkoutSession: CheckoutSession = await postRes.json()
  return checkoutSession
}

interface SubmitOrderResPayload {
  order: {
    id: string
    totalInMinorUnits: number
  }
}
export async function submitOrder(payload: SubmitOrderPayload) {
  const clone: Basket = JSON.parse(JSON.stringify(payload))
  clone.total = round(clone.total)

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const postRes = await fetch(`${OO_BE_URL_V1}order`, {
    method: 'POST',
    body: JSON.stringify(clone),
    headers,
  })

  const { order }: SubmitOrderResPayload = await postRes.json()
  return order
}

export async function getOrder(orderId: string) {
  const orderRes = await fetch(`${OO_BE_URL_V1}order/${orderId}`)
  const order: ApiOrder = await orderRes.json()
  return order
}

export async function getApiInfo() {
  const infoRes = await fetch(`${OO_BE_URL_V1}info`)
  const info: ApiInfo = await infoRes.json()
  return info
}

export async function getShopData(shopId: string): Promise<ShopInfo> {
  const shopRes = await fetch(`${OO_BE_URL_V1}shop/${shopId}`)
  const {
    merchant,
    store,
    menus,
    modifiers,
    products,
    isOpenAtm,
  }: ApiShopInfo = await shopRes.json()

  const menuByCategories: MenuByCategories = menus.map((m) => {
    return {
      categoryName: m.name,
      items: m.menuProducts.map((mp) => {
        const apiProduct = products.find((p) => p.id === mp.product_id)!
        const productModifiers = modifiers
          .filter((m) => apiProduct.modifierIds.includes(m.id))
          .map((m) => {
            return {
              ...m,
              options: m.options.map(({ label, price, id }) => {
                return { label, id, price: +price }
              }),
            }
          })

        const singleSelectModifiers: SelectModifier[] = productModifiers
          .filter((m) => !m.multiSelect)
          .map((m) => {
            return {
              ...m,
              selected: m.options[0],
            }
          })

        const multiSelectModifiers: MultiSelectModifier[] = productModifiers
          .filter((m) => m.multiSelect)
          .map((m) => {
            return {
              ...m,
              selected: [],
            }
          })

        return {
          ...apiProduct,
          basePrice: +apiProduct.basePrice,
          qty: 0,
          singleSelectModifiers,
          multiSelectModifiers,
        }
      }),
    }
  })

  return {
    isOpenAtm,
    merchant,
    store: { ...store, calendarParsed: JSON.parse(store.calendar) },
    menuByCategories,
  }
}
