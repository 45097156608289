// export const OO_BE_URL =
//   process.env.REACT_APP_OO_BE_URL ?? 'http://localhost:3000'
export const OO_BE_URL =
  process.env.REACT_APP_OO_BE_URL ?? 'https://onlineorder-master.herokuapp.com'
export const OO_BE_URL_V1 = `${OO_BE_URL}/v1/`
export const QITEA_MERC_NAME_ID = 'qitea'
export const shopBasketLocalStorageKey = `${QITEA_MERC_NAME_ID}_basket`
export const customerInfoLocalStorageKey = `${QITEA_MERC_NAME_ID}_customer_info`

const developmentSentryDns =
  'https://23d182b1a82943a09226a937258b2279@o1335046.ingest.sentry.io/6602443'
export const SENTRY_DNS =
  process.env.REACT_APP_SENTRY_DNS ?? developmentSentryDns

const devStripePublishableKey =
  'pk_test_51LQtWaK67uT07qudupuKPWeNBsFPlkkeBmLM2xop9URZ6e82w9scYp3GvEs474U4Y7IpqKOD8SB17KPtrnn3UQsP00dWi0C151'
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? devStripePublishableKey

export const REACT_APP_MOCK_CUSTOMER_INFO =
  process.env.REACT_APP_MOCK_CUSTOMER_INFO === 'true'
