import { DateTime, WeekdayNumbers } from 'luxon'

export interface CustomerInfo {
  name: string
  email: string
  phone: string
}
export interface Basket {
  items: Product[]
  total: number
  showBasket: boolean
}

export type MenuByCategories = Array<{
  categoryName: string
  items: Product[]
}>

export interface Product extends ApiProduct {
  qty: number
  singleSelectModifiers: SelectModifier[]
  multiSelectModifiers: MultiSelectModifier[]
}

export interface ShopInfo {
  merchant: Merchant
  store: Store
  menuByCategories: MenuByCategories
  isOpenAtm: boolean
}

export interface ApiShopInfo {
  isOpenAtm: boolean
  merchant: Merchant
  store: ApiStore
  menus: Array<{
    name: string
    menuProducts: Array<{
      menu_id: number
      product_id: number
    }>
  }>
  products: Array<ApiProduct>
  modifiers: Array<ApiModifier>
}

export interface ApiInfo {
  version: number
}

export interface ApiProduct {
  id: number
  modifierIds: number[]
  basePrice: number
  title: string
  relImage: string
  image: string
  popular: boolean
  available: boolean
  description: string
}

export interface ModifierOption {
  id: number
  label: string
  price: number
}

export interface ApiModifier {
  id: number
  name: string
  note: string
  multiSelect: boolean
  options: ModifierOption[]
}

export interface SelectModifier extends ApiModifier {
  selected: ModifierOption
}

export interface MultiSelectModifier extends ApiModifier {
  selected: ModifierOption[]
}

export enum ToppingOption {
  PoppingBoba = 'Popping Boba',
  BrownPearls = 'Brown Pearls',
  CheeseFoam = 'Cheese Foam',
  CoffeeJely = 'Coffee Jelly',
}

export interface Merchant {
  id: string
  name: string
  slogan: string
  stores: Array<Store>
}

export interface Store extends ApiStore {
  calendarParsed: Array<{ day: string; time: string }>
}

export interface ApiStore {
  merchantId: string
  name: string
  bannerImgUrl: string
  locationId: string
  calendar: string
  tel: string
  site: string
  instagram: string
  location: string
  googleMapsShorted: string
  openingHours: ApiOpeningHour[]
}

export interface OpeningHourValue {
  day: WeekdayNumbers
  isOpen: boolean
  openTime?: DateTime
  closeTime?: DateTime
}
export type OpeningHours = { [key in WeekdayNumbers]: OpeningHourValue }

export interface ApiOpeningHour {
  day: WeekdayNumbers
  openTime: string // HH:mm:ss
  closeTime: string // HH:mm:ss
}

export interface ApiOrder {
  id: string
  jsonContent: string
  total: number
  ccy: string
  status: ApiOrderStatus
  email: string
  mobile: string
  name: string
  orderNo: number
  confirmedTs: string
  createdTs: string
  rejectedTs: string
  readyTs: string
}

export enum ApiOrderStatus {
  CREATED = 'CREAT',
  CONFIRMED = 'CONFI',
  READY = 'READY',
  DELIVERED = 'DELIV',
  CANCELLED = 'CANCL',
  REJECTED = 'REJEC',
}

export enum BamboraPaymentMethod {
  MOBILEPAY = 'MOBILEPAY',
  CARD = 'CARD',
}

export interface GlobalAppContext {
  shopInfo: ShopInfo
  openingHours: OpeningHours // alreadySorted
  isOpenAtm: boolean
}
