import { DateTime } from 'luxon'
import { createContext } from 'react'
import { ShopInfo, GlobalAppContext } from './models/domain'
import { getAllOpeningHours } from './util'

export const AppContext = createContext<GlobalAppContext | null>(null)

export const getAppContext = ({
  shopInfo,
}: {
  shopInfo: ShopInfo
}): GlobalAppContext => {
  const { store, isOpenAtm } = shopInfo
  const openingHours = getAllOpeningHours(store.openingHours)

  return {
    shopInfo,
    openingHours,
    isOpenAtm,
  }
}
