import React, { useState } from 'react'
import './Quantity.scss'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface QuantityProp {
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  /* set to true if used to display only */
  immutable?: boolean
}

export function Quantity({ quantity, setQuantity, immutable }: QuantityProp) {
  const increment = () => setQuantity(quantity + 1)
  const decrement = () => {
    const newQuantity = quantity === 1 ? 1 : quantity - 1
    setQuantity(newQuantity)
  }
  return (
    <div className="quantity-input fs-3">
      {!immutable && (
        <span onClick={decrement} className="quantity-decrease-btn">
          <FontAwesomeIcon icon={faMinusCircle} />
        </span>
      )}
      <span>
        {quantity}
        {immutable && 'x'}
      </span>
      {!immutable && (
        <span onClick={increment} className="quantity-increase-btn">
          <FontAwesomeIcon icon={faPlusCircle} />
        </span>
      )}
    </div>
  )
}
