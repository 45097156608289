import { useEffect, useState } from 'react'
import fetch from 'node-fetch'
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import StatusMessages, { useMessages } from './StatusMessages'
import { OO_BE_URL_V1 } from '../config'
import { useNavigate } from 'react-router-dom'
import { checkPmtSessionSuccess } from '../services/oo-be'

interface ApplePayProps {
  totalInMinorUnits: number
  orderId: string
}
const ApplePay = (props: ApplePayProps) => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  )
  const [messages, addMessage] = useMessages()

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      country: 'FI',
      currency: 'eur',
      total: {
        label: 'Qitea by SmartDine',
        amount: props.totalInMinorUnits,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr as any)
      }
    })

    pr.on('paymentmethod', async (event) => {
      const paymentIntentRes = await fetch(
        `${OO_BE_URL_V1}payment/create-payment-intent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodType: 'card',
            currency: 'eur',
            totalInMinorUnits: props.totalInMinorUnits,
            orderId: props.orderId,
          }),
        }
      )

      if (!paymentIntentRes.ok) {
        addMessage('Failed to create payment intent')
        event.complete('fail')
        return
      }

      const { error: backendError, clientSecret } =
        await paymentIntentRes.json()
      if (backendError) {
        addMessage(backendError.message)
        event.complete('fail')
        return
      }

      addMessage('Client secret returned')

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false }
        )

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        debugger
        addMessage(stripeError.message)
        event.complete('fail')
        return
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      addMessage(`Payment ${paymentIntent?.status}: ${paymentIntent?.id}`)
      event.complete('success')

      const success = await checkPmtSessionSuccess(
        paymentIntent?.id,
        props.orderId
      )
      success && navigate(`/qitea/order/${props.orderId}`)
    })
  }, [stripe, elements, addMessage])

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest } as any} />
      )}
    </>
  )
}

export default ApplePay
